import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import dayjs from 'dayjs';

import { REGEXP } from 'consts';
import { styled } from 'styles';
import { disablePastDates } from 'utils/date';
import { Button, CurrencyInput, DateInput, RadioButton, Dropdown, Input } from 'components';

const formatDate = value => {
  return dayjs(value).format('MM/DD/YY');
};

interface DeadlineFormProps {
  onSubmit: (values: { [key: string]: any; }) => void;
  defaultValues?: { [key: string]: any; };
}

const DeadlineForm: React.FC<DeadlineFormProps> = ({ onSubmit, defaultValues }) => {
  const { register, control, errors, handleSubmit, watch } = useForm({
    defaultValues: {
      ...defaultValues
    }
  });
  const homeWarranty = watch('homeWarranty');

  console.log(homeWarranty);

  return (
    <StyledForm className="deadline-form" onSubmit={handleSubmit(onSubmit)}>
      <p>When do you want to close?</p>
      <div className="deadline-form__input-group">
        <Controller
          control={control}
          rules={{
            required: 'Required',
            validate: value => dayjs(value).isValid() || `Invalid date`
          }}
          name="deadlineDate"
          render={({ onChange, ...controllerProps }) => (
            <DateInput
              inputProps={{
                label: 'Select Date*',
                placeholder: 'MM/DD/YY',
                error: errors.deadlineDate
              }}
              onChange={value => onChange(formatDate(value))}
              isValidDate={disablePastDates}
              className="deadline-form__input"
              {...controllerProps}
            />
          )}
        />
      </div>
      <p className="deadline-form__description">
        For this home we recommend a 30-day close. Our expert will work with you to suggest a
        timeline that meets your needs and makes your offer both competitive and relevant
      </p>
      <hr />
      <div className="offset-top" />
      <p>Inspection needed?</p>
      <div className="deadline-form__radio-group">
        <RadioButton ref={register} value="yes" name="inspectionChoice" label="Yes" />
        <RadioButton ref={register} value="no" name="inspectionChoice" label="No" />
        <RadioButton ref={register} value="asIs" name="inspectionChoice" label="As is" />
      </div>
      <div className="offset-top" />
      <p>Home Warranty?</p>
      <div className="deadline-form__radio-group">
        <RadioButton ref={register} value="yes" name="homeWarranty" label="Yes" />
        <RadioButton ref={register} value="no" name="homeWarranty" label="No" />
      </div>
      <div className="deadline-form__input-group">
        {homeWarranty === 'yes' && (
          <Controller
            control={control}
            name="homeWarrantyPrice"
            defaultValue={null}
            render={controllerProps => (
              <CurrencyInput
                label="Home Warranty Price"
                placeholder="Enter Home Warranty Price"
                min={0}
                error={errors.homeWarrantyPrice}
                className="deadline-form__input"
                {...controllerProps}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="taxProration"
          defaultValue={null}
          render={controllerProps => (
            <Dropdown
              options={[
                {
                  value: '3%',
                  text: '3%'
                },
                {
                  value: '10%',
                  text: '10%'
                },
                {
                  value: '50%',
                  text: '50%'
                }
              ]}
              error={errors.taxProration}
              label="Tax proration"
              placeholder="Select Percentage"
              className="deadline-form__input"
              {...controllerProps}
            />
          )}
        />
      </div>
      <div className="deadline-form__input-group">
        <Input
          className="deadline-form__input"
          ref={register({
            pattern: {
              value: REGEXP.NAME,
              message: 'Invalid name'
            }
          })}
          name="attorneyName"
          error={errors.attorneyName}
          label="Attorney Name"
          placeholder="Enter Attorney Name"
        />
        <Input
          ref={register({
            pattern: {
              value: REGEXP.EMAIL,
              message: 'Invalid email address'
            }
          })}
          name="attorneyEmail"
          error={errors.attorneyEmail}
          className="deadline-form__input"
          label="Attorney Email"
          placeholder="Enter Attorney Email"
        />
      </div>

      <div className="deadline-form__input-group">
        <Controller
          control={control}
          name="attorneyPhone"
          rules={{
            validate: value => !value.includes('_')
          }}
          defaultValue=""
          render={controllerProps => (
            <Input
              className="deadline-form__input"
              as={MaskedInput}
              mask="+1 (999) 999-9999"
              type="tel"
              error={errors.attorneyPhone}
              label="Attorney Phone"
              placeholder="Enter Attorney Phone"
              {...controllerProps}
            />
          )}
        />
      </div>
      <div className="deadline-form__input-group">
        <Input
          className="deadline-form__input"
          ref={register({
            pattern: {
              value: REGEXP.NAME,
              message: 'Invalid name'
            }
          })}
          name="lenderName"
          error={errors.lenderName}
          label="Lender Name"
          placeholder="Enter Lender Name"
        />
        <Input
          ref={register({
            pattern: {
              value: REGEXP.EMAIL,
              message: 'Invalid email address'
            }
          })}
          name="lenderEmail"
          error={errors.lenderEmail}
          className="deadline-form__input"
          label="Lender Email"
          placeholder="Enter Lender Email"
        />
      </div>

      <div className="deadline-form__input-group">
        <Controller
          control={control}
          name="lenderPhone"
          rules={{
            validate: value => !value.includes('_')
          }}
          defaultValue=""
          render={controllerProps => (
            <Input
              className="deadline-form__input"
              as={MaskedInput}
              mask="+1 (999) 999-9999"
              type="tel"
              error={errors.lenderPhone}
              label="Lender Phone"
              placeholder="Enter Lender Phone"
              {...controllerProps}
            />
          )}
        />
      </div>
      <Button className="deadline-form__submit-btn" primary>
        Continue
      </Button>
    </StyledForm>
  );
};

export default DeadlineForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0 0 12px 0;
  }

  .input,
  .dropdown {
    &__component {
      height: 40px;
    }
    label {
      font-size: 10px;
      line-height: 16px;
    }
  }

  .deadline-form__input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
  }
  .deadline-form__input {
    width: 100%;
    .dropdown__button {
      height: 40px;
    }
  }

  .deadline-form__radio-group {
    & > .label {
      margin-bottom: 8px;
    }
    .radiobutton {
      margin-right: 20px;
    }
  }
  .deadline-form__description {
    color: ${props => props.theme.colors.etherealGrey};
    margin: 6px 0 0;
    font-size: 12px;
    line-height: 16px;
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .deadline-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .deadline-form__input-group {
      grid-template-columns: 1fr;
      grid-gap: 12px 0;
    }
    .deadline-form__submit-btn {
      width: 100%;
    }
  }
`;
