import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useQueryParam } from 'use-query-params';

import notify from 'notify';
import { getListingDetails } from 'api/listings';
import { tryGetFirstError } from 'utils/requests';
import { submitBuyProcessForm } from './api';

interface BuyProcessContextValues {
  activeTab: BuyProcessTabKey;
  setActiveTab: React.Dispatch<React.SetStateAction<BuyProcessTabKey>>;
  listing?: ListingDetails;
  listingId: string;
  introTabData?: { [key: string]: any };
  setIntroTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any }> | undefined>;
  infoTabData?: { [key: string]: any };
  setInfoTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any } | undefined>>;
  financeTabData?: { [key: string]: any };
  setFinanceTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any } | undefined>>;
  deadlineTabData?: { [key: string]: any };
  setDeadlineTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any } | undefined>>;
  showPhoneValidation: boolean;
  onReviewSubmit: () => void;
  buyProcessId?: string;
}

const BuyProcessContext = React.createContext({} as BuyProcessContextValues);

export const BuyProcessContextWrapper = ({ children }) => {
  const [listingId] = useQueryParam<string>('id');
  const [activeTab, setActiveTab] = useState<BuyProcessTabKey>('intro');
  const [listing, setListing] = useState<ListingDetails>();
  const [introTabData, setIntroTabData] = useState<{ [key: string]: any }>();
  const [infoTabData, setInfoTabData] = useState<{ [key: string]: any }>();
  const [financeTabData, setFinanceTabData] = useState<{ [key: string]: any }>();
  const [deadlineTabData, setDeadlineTabData] = useState<{ [key: string]: any }>();
  const [showPhoneValidation, setShowPhoneValidation] = useState(false);
  const [buyProcessId, setBuyProcessId] = useState<string | undefined>();

  useEffect(() => {
    if (!listingId) {
      navigate('/');
      return;
    }
    (async () => {
      try {
        const data = await getListingDetails(listingId);
        setListing(data);
      } catch (err) {
        notify(tryGetFirstError(err) || err.message);
        navigate('/');
      }
    })();
  }, [listingId]);

  const onReviewSubmit = async () => {
    try {
      const formValues = {
        ...introTabData,
        ...infoTabData,
        ...financeTabData,
        ...deadlineTabData,
        listing: {
          mlsId: listing?.mlsId,
          address1: listing?.address1,
          address2: listing?.address2,
          city: listing?.city,
          state: listing?.state,
          postalCode: listing?.postalCode,
          images: listing?.images
        }
      };
      const buyProcessId = await submitBuyProcessForm(formValues, listingId);
      setBuyProcessId(buyProcessId);
      setShowPhoneValidation(true);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const contextValues = {
    activeTab,
    setActiveTab,
    listing,
    listingId,
    introTabData,
    setIntroTabData,
    infoTabData,
    setInfoTabData,
    financeTabData,
    setFinanceTabData,
    deadlineTabData,
    setDeadlineTabData,
    onReviewSubmit,
    showPhoneValidation,
    buyProcessId
  };

  return <BuyProcessContext.Provider value={contextValues}>{children}</BuyProcessContext.Provider>;
};

export default BuyProcessContext;
