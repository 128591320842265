import React, { useContext } from 'react';
import { Button } from 'components';
import { styled } from 'styles';
import Container from 'components/Container';
import Icon from 'components/Icon';
import FinanceForm from './FinanceForm';
import BuyProcessContext from '../context';

const Finance = () => {
  const { listing, financeTabData, setFinanceTabData, activeTab, setActiveTab } = useContext(
    BuyProcessContext
  );
  const open = activeTab === 'finance';

  const handleSubmit = value => {
    setFinanceTabData(value);
    setActiveTab('deadline');
  };

  return (
    <>
      {open && (
        <Container info>
          <Icon name="real-estate1" /> Please submit your financial information
        </Container>
      )}
      <StyledHeaderContainer number={3} header active={open} merged={open ? 'top' : undefined}>
        Finance{' '}
        {financeTabData && !open && (
          <Button link onClick={() => setActiveTab('finance')}>
            Edit
          </Button>
        )}
      </StyledHeaderContainer>
      {open && listing && (
        <Container merged="bottom">
          <FinanceForm listing={listing} onSubmit={handleSubmit} defaultValues={financeTabData} />
        </Container>
      )}
    </>
  );
};

export default Finance;

const StyledHeaderContainer = styled(Container)`
  .button {
    float: right;
  }
`;
