import React, { useContext } from 'react';

import { styled } from 'styles';
import { priceFormatter } from 'utils/formatters';
import noImage from 'assets/images/placeholder_no_image.jpg';
import { Button, RadioButton, Image } from 'components';
import Container from 'components/Container';
import { IMAGE_SIZES } from 'consts';
import BuyProcessContext from '../context';

const Intro = () => {
  const { listing, activeTab, introTabData, setIntroTabData, setActiveTab } = useContext(
    BuyProcessContext
  );
  const propertyImage = listing?.images?.[0];
  const open = activeTab === 'intro';

  const handleButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setIntroTabData({ [name]: value });
    setActiveTab('info');
  };

  return open ? (
    <StyledIntro>
      <div className="property-header-info">
        <h3 className="property-header-info__title">You’re making offer for:</h3>
        <span className="property-header-block">
          {propertyImage ? (
            <Image
              cloudFront={{ size: IMAGE_SIZES.small, key: propertyImage }}
              alt="mini-property"
              className="property-photo"
            />
          ) : (
            <img src={noImage} alt="mini-propetry" />
          )}
          <span>
            <span>
              <strong>{listing?.address1},</strong> {listing?.city}, {listing?.state}{' '}
              {listing?.postalCode}
            </span>
            <span className="price-text">
              Price for Sale:{' '}
              <span className="price">{priceFormatter.format(Number(listing?.price))}</span>
            </span>
          </span>
        </span>
      </div>
      <div className="property-header-info">
        <h3 className="property-header-info__title">Are you a buyer or an agent?</h3>
        <div className="process-form__radio-block">
          <StyledRadioButton
            name="buyerOrAgent"
            value="buyer"
            label={
              <span>
                <strong>I am Buyer</strong>
                Start drafting your non-binding agreement.
              </span>
            }
            checked={introTabData?.buyerOrAgent === 'buyer'}
            onChange={handleButtonClick}
            buttonLike
          />
          <StyledRadioButton
            name="buyerOrAgent"
            value="agent"
            label={
              <span>
                <strong>I am an Agent</strong>
                Get in touch with a listing agent.
              </span>
            }
            checked={introTabData?.buyerOrAgent === 'agent'}
            onChange={handleButtonClick}
            buttonLike
          />
        </div>
      </div>
    </StyledIntro>
  ) : (
    <Container number={1} header active={open} merged={open ? 'top' : undefined}>
      Intro{' '}
      {introTabData && !open && (
        <Button link onClick={() => setActiveTab('intro')} className="edit-btn">
          Edit
        </Button>
      )}
    </Container>
  );
};

const StyledIntro = styled.div`
  .property-header-info {
    border: 1px solid #dadada;
    padding: 24px 24px 32px;
    border-radius: ${props => props.theme.misc.borderRadius};
    max-width: 620px;
    margin: 0 auto 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 24px;
    }
  }
  .property-header-block {
    flex: 1;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    display: flex;
    img {
      width: 64px;
      height: 64px;
      border-radius: ${props => props.theme.misc.borderRadius};
      margin-right: 32px;
      object-fit: cover;
    }
    span {
      font-size: 20px;
      line-height: 24px;
      display: block;
      font-weight: normal;
      strong {
        font-weight: 500;
      }
    }
    .price-text {
      font-size: 16px;
      line-height: 24px;
      color: #9c9c9e;
      margin: 4px 0 0;
    }
    .price {
      font-size: 16px;
      line-height: 24px;
      color: black;
      display: inline;
    }
  }
  .img-error {
    object-fit: cover !important;
  }
  .process-form__radio-block {
    margin: 22px 0 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .property-header-info {
      padding: 24px 16px;

      &__title {
        font-size: 14px;
      }
    }
    .property-header-block {
      flex-direction: column;
      align-items: center;
      span {
        font-size: 14px;
        text-align: center;
      }
      .price-text {
        font-size: 14px;
      }
      .price {
        font-size: 14px;
      }
    }
    .process-form__radio-block {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`;
const StyledRadioButton = styled(RadioButton)`
  &.button-like {
    .label {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      max-width: 188px;
      color: #9c9c9e;
      text-align: center;
      strong {
        display: block;
        font-weight: 500;
        color: #000;
      }
    }
    .checkmark {
      background: transparent;
      border-radius: ${props => props.theme.misc.borderRadius};
      border-color: #c4c4c4;
      width: 246px;
      height: 131px;
      padding: 30px;
    }
    input:checked {
      ~ .checkmark {
        border-radius: ${props => props.theme.misc.borderRadius};
        border-color: ${props => props.theme.colors.primary};
        background: transparent;
        &:after {
          background: transparent;
        }
      }
      ~ .label {
        color: #9c9c9e;
        strong {
          color: #000;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 0;
    &:first-of-type {
      margin: 0 0 16px;
    }
  }
`;

export default Intro;
