import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import notify from 'notify';
import { styled } from 'styles';
import { tryGetFirstError } from 'utils/requests';
import { Button, Container, Input, Timer } from 'components';
import BuyProcessContext from '../context';
import { generateValidationCode, submitValidationCode } from '../api';
import ConfirmationScreen from './ConfirmationScreen';

function sec2time(timeInSeconds) {
  const pad = (num, size) => `000${num}`.slice(size * -1);
  const time = Number(parseFloat(timeInSeconds).toFixed(3));
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);

  return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
}

const ValidatePhone = () => {
  const { register, errors, setError, getValues } = useForm();
  const { infoTabData, buyProcessId } = useContext(BuyProcessContext);
  // eslint-disable-next-line prefer-destructuring
  const phone: string = infoTabData?.phone || '+13128136580';
  const [showTimer, setShowTimer] = useState(false);
  const [codeId, setCodeId] = useState<string | null>(null);
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const errorText = errors.general?.message;

  const handleTimeout = () => {
    setShowTimer(false);
  };

  const submitPhoneValidation = useCallback(async () => {
    try {
      const codeId = await generateValidationCode(phone, buyProcessId);
      setCodeId(codeId);
      setShowTimer(true);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  }, [phone]);

  const submit = async () => {
    const values = getValues();
    try {
      if (!codeId) throw Error('No verification code ID');
      await submitValidationCode({ codeId, code: values.securityCode });
      setShowConfirmationScreen(true);
    } catch (err) {
      setError('general', { type: 'manual', message: tryGetFirstError(err) || err.response });
    }
  };

  useEffect(() => {
    submitPhoneValidation();
  }, [submitPhoneValidation]);

  if (showConfirmationScreen) return <ConfirmationScreen />;

  return (
    <StyledContainer>
      <h3 className="title">Validate your phone number</h3>
      <p className="description">
        We sent an SMS with security code to your phone number <br />{' '}
        <strong>xxx-xxx-{phone.substring(phone.length - 4)}</strong>
      </p>
      <form onSubmit={e => e.preventDefault()}>
        <Input
          ref={register({ required: 'Required' })}
          name="securityCode"
          error={errors.securityCode}
          label="Security Code"
          placeholder="Enter Code"
        />
        {showTimer ? (
          <Timer
            onTimeout={handleTimeout}
            time={60}
            text={({ timeLeft }) => (
              <div className="text">
                Resend in <span className="time">{sec2time(timeLeft)}</span>
              </div>
            )}
          />
        ) : (
            <Button className="resend-btn" link type="button" onClick={submitPhoneValidation}>
              ↺ Resend Security code
            </Button>
          )}
        <div className="error-text">{errorText}</div>
        <Button primary type="submit" className="submit-btn" onClick={submit}>
          Continue
        </Button>
      </form>
    </StyledContainer>
  );
};

export default ValidatePhone;

const StyledContainer = styled(Container)`
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 20px auto;
  }
  .description {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 45px auto;
  }
  .title,
  .description {
    max-width: 384px;
  }

  form {
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 315px;

    .input {
      width: 100%;
      label {
        font-size: 10px;
      }
      &__component {
        height: 36px;
      }
    }

    .resend-btn {
      margin: 12px auto 16px 0;
    }

    .timer {
      width: 100%;
      display: inline-flex;
      font-size: 10px;
      line-height: 24px;
      text-align: center;
      color: #c4c4c4;
      margin: 16px 0;

      .time {
        color: #000;
      }
    }

    .submit-btn {
      height: 36px;
      margin: 16px 0;
    }

    .error-text {
      color: ${props => props.theme.colors.errorRed};
    }
  }
`;
