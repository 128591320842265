import React, { useMemo } from 'react';
import { useRifm } from 'rifm';
import { Controller, useForm } from 'react-hook-form';
import { styled } from 'styles';
import {
  Button,
  CurrencyInput,
  FileInput,
  FileInputACCEPT,
  RadioButton,
  Slider,
  Tooltip,
  Icon,
  Dropdown
} from 'components';
import Input, { InputProps } from 'components/Input';
import { uploadFileToS3 } from 'api/documents';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { formatCurrency } from 'utils/rifmFormatters';
import { calculateDiscount } from 'utils/listing';

const stripNonDigits = (str: string) => (str ? Number(str.replaceAll(/[^0-9.]/g, '')) || 0 : 0);

const PercentInput: React.FC<Omit<InputProps, 'onChange'> & { onChange: (string) => void }> = ({
  value = '',
  onChange,
  ...props
}) => {
  const format = value => {
    if (value === 0) return '0%';
    if (!value) return '';
    let parsedValue = String(value).replaceAll(/[^0-9.]/g, '');
    if (Number(parsedValue) > 100) parsedValue = '100';
    else if (Number(parsedValue) < 0) parsedValue = '0';
    return `${parsedValue}%`;
  };

  const rifm = useRifm({
    accept: /\d/g,
    value: value as string,
    onChange: value => {
      let valueNumber = stripNonDigits(value);
      if (valueNumber < 0) valueNumber = 0;
      else if (valueNumber > 100) valueNumber = 100;
      onChange?.(valueNumber);
    },
    format
  });

  return <Input value={rifm.value} onChange={rifm.onChange} {...props} />;
};

const MIN_PRICE = 70;
const MAX_PRICE = 130;

const percentage = (percent: number, total: number, precise?: boolean): number => {
  const result = (percent / 100) * total;
  return precise ? Number(result.toFixed(2)) : Math.round(result);
};

type FileUpload = { url: string; name: string };

type Range = {
  min: number;
  max: number;
};

interface FinanceFormProps {
  listing: ListingDetails;
  defaultValues?: { [key: string]: any };
  onSubmit: (values: { [key: string]: any }) => void;
}

const FinanceForm: React.FC<FinanceFormProps> = ({ listing, onSubmit, defaultValues }) => {
  const { register, control, errors, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      offerAmount: listing.price,
      depositAmount: listing.price * 0.1, // 10%
      escrowDeposit: 1000,
      approvalLetter: [] as FileUpload[],
      preapprove: 'yes',
      proof: 'yes',
      paymentMethod: 'financing',
      loanAmount: '',
      selectionLoanType: null,
      interestRate: null,
      selectLoanPeriod: null,
      preapproveByLender: 'yes',
      ...defaultValues
    }
  });
  const { offerAmount, preapprove, preapproveByLender, paymentMethod, proof } = watch([
    'offerAmount',
    'preapprove',
    'preapproveByLender',
    'paymentMethod',
    'proof'
  ]);

  const offerAmountRange: Range = useMemo(
    () => ({
      min: percentage(MIN_PRICE, listing.price),
      max: percentage(MAX_PRICE, listing.price)
    }),
    [listing.price]
  );

  const getFilesLabel = files => {
    const { length } = files;
    if (!length) return 'Select file...';
    return files[0].name;
  };

  const onFileUpload = async file => {
    try {
      const url = await uploadFileToS3(file);
      setValue('approvalLetter', [{ url, name: file.name }]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const onFileRemove = () => {
    setValue('approvalLetter', []);
  };

  return (
    <StyledForm className="finance-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="offset-top" />
      <p>Please choose your preferred method of payment.</p>
      <div className="finance-form__radio-group">
        <RadioButton
          ref={register({ required: true })}
          name="paymentMethod"
          value="cash"
          label="All Cash"
          buttonLike
        />
        <RadioButton
          ref={register({ required: true })}
          name="paymentMethod"
          label="Financing"
          value="financing"
          buttonLike
          defaultChecked
        />
      </div>
      <hr />
      <div className="finance-form__input-group">
        <div className="finance-form__slider-input">
          <Controller
            control={control}
            name="offerAmount"
            defaultValue=""
            rules={{ required: 'Required' }}
            render={({ value, onChange, onBlur }) => {
              const aboveOrBelow = Math.abs(Math.round((offerAmount / listing.price) * 100 - 100));
              return (
                <>
                  <CurrencyInput
                    label={
                      <>
                        Offer Amount*{' '}
                        <Tooltip
                          placement="left"
                          trigger={
                            <Icon
                              name="tooltip-trigger"
                              className="tooltip-trigger"
                              width="14"
                              height="14"
                            />
                          }>
                          <p className="tooltip-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur
                            varius elit, vel aliquet diam. Etiam nec orci vel leo semper iaculis non
                            nec metus. Praesent posuere mauris sed.
                          </p>
                        </Tooltip>
                      </>
                    }
                    placeholder="Enter"
                    value={value}
                    error={errors.offerAmount}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <Slider
                    min={offerAmountRange.min}
                    max={offerAmountRange.max}
                    step={1000}
                    value={offerAmount || 0}
                    onChange={onChange}
                  />
                  <div className="finance-form__slider-input__info">
                    Est. back at closing:{' '}
                    <strong className="green">{calculateDiscount(offerAmount) || '$0'}</strong>
                  </div>
                  <div className="finance-form__slider-input__info">
                    List price: <strong>{formatCurrency(String(listing.price))}</strong>
                  </div>
                  {value && (
                    <div className="finance-form__slider-input__info">
                      Your offer is <strong>{aboveOrBelow}%</strong>{' '}
                      {offerAmount > listing.price ? 'above' : 'below'} list
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
        <div className="finance-form__slider-input">
          <Controller
            control={control}
            name="depositAmount"
            defaultValue=""
            rules={{ required: 'Required' }}
            render={({ value, onChange, onBlur }) => {
              const percent = Math.floor((value / offerAmount) * 100);
              return (
                <>
                  <StyledDoubleInput className="double-input">
                    <CurrencyInput
                      label={
                        <>
                          Deposit Amount*{' '}
                          <Tooltip
                            placement="left"
                            trigger={
                              <Icon
                                name="tooltip-trigger"
                                className="tooltip-trigger"
                                width="14"
                                height="14"
                              />
                            }>
                            <p className="tooltip-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                              efficitur varius elit, vel aliquet diam. Etiam nec orci vel leo semper
                              iaculis non nec metus. Praesent posuere mauris sed.
                            </p>
                          </Tooltip>
                        </>
                      }
                      placeholder="Enter"
                      value={value}
                      error={errors.depositAmount}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                    <PercentInput
                      className="percent-input"
                      value={percent}
                      onChange={value => onChange((value * offerAmount) / 100)}
                    />
                  </StyledDoubleInput>
                  <Slider
                    value={percent || 0}
                    onChange={value => onChange(((value as number) * offerAmount) / 100)}
                  />
                </>
              );
            }}
          />
        </div>
        <div className="finance-form__slider-input">
          <Controller
            control={control}
            name="escrowDeposit"
            defaultValue=""
            rules={{ required: 'Required' }}
            render={({ value, onChange, onBlur }) => (
              <>
                <CurrencyInput
                  label={
                    <>
                      Escrow Deposit*{' '}
                      <Tooltip
                        placement="left"
                        trigger={
                          <Icon
                            name="tooltip-trigger"
                            className="tooltip-trigger"
                            width="14"
                            height="14"
                          />
                        }>
                        <p className="tooltip-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur
                          varius elit, vel aliquet diam. Etiam nec orci vel leo semper iaculis non
                          nec metus. Praesent posuere mauris sed.
                        </p>
                      </Tooltip>
                    </>
                  }
                  placeholder="Enter"
                  value={value}
                  error={errors.escrowDeposit}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Slider max={1000000} step={1000} value={value || 0} onChange={onChange} />
              </>
            )}
          />
        </div>
      </div>
      <hr />
      {paymentMethod === 'financing' && (
        <div className="finance-form__radio-group">
          <div className="label">Are you already preapproved?</div>
          <RadioButton ref={register} name="preapprove" value="yes" label="Yes" />
          <RadioButton ref={register} name="preapprove" value="no" label="No" />
        </div>
      )}

      {paymentMethod === 'financing' && preapprove === 'no' && (
        <div className="finance-form__radio-group">
          <div className="label">
            Are you already in the process of being preapproved by your lender? If yes, you agree to
            submitting an offer status as "waiting for pre approval".
          </div>
          <RadioButton
            ref={register}
            name="preapproveByLender"
            value="yes"
            label="Yes"
            defaultChecked
          />
          <RadioButton ref={register} name="preapproveByLender" value="no" label="No" />
        </div>
      )}

      {paymentMethod === 'financing' && (
        <>
          {preapproveByLender !== 'no' && (
            <div className="finance-form__radio-group">
              <div className="label">Fixed or adjustable loan?</div>
              <RadioButton ref={register} name="loanType" value="fixed" label="Fixed" />
              <RadioButton
                ref={register}
                name="loanType"
                value="adjustable"
                label="Adjustable"
                defaultChecked
              />
            </div>
          )}

          {preapproveByLender !== 'no' && (
            <>
              <div className="finance-form__input-container">
                <Controller
                  control={control}
                  name="loanBase"
                  render={controllerProps => (
                    <Dropdown
                      options={[
                        { value: 'Conventional', text: 'Conventional' },
                        { value: 'FHA', text: 'FHA' },
                        { value: 'VA', text: 'VA' },
                        { value: 'USDA', text: 'USDA' }
                      ]}
                      error={errors.selectLoanPeriod}
                      label="Loan Base"
                      placeholder="Select Conventional, FHA, VA or USDA"
                      className="finance-form__input"
                      {...controllerProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="interestRate"
                  render={({ value, onChange, onBlur }) => (
                    <PercentInput
                      label={<>Interest Rate</>}
                      placeholder="Enter percentage"
                      value={value}
                      error={errors.interestRate}
                      className="finance-form__input"
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              <div className="finance-form__input-container">
                <Controller
                  control={control}
                  name="loanAmount"
                  defaultValue=""
                  render={controllerProps => (
                    <CurrencyInput
                      label="Loan Amount"
                      placeholder="Enter Amount"
                      className="finance-form__input"
                      error={errors.loanAmount}
                      {...controllerProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="selectLoanPeriod"
                  render={controllerProps => (
                    <Dropdown
                      options={[
                        { value: '1 year', text: '1 year' },
                        { value: '5 years', text: '5 years' },
                        { value: '10 years', text: '10 years' },
                        { value: '15 years', text: '15 years' },
                        { value: '20 years', text: '20 years' },
                        { value: '25 years', text: '25 years' },
                        { value: '30 years', text: '30 years' }
                      ]}
                      error={errors.selectLoanPeriod}
                      label="Loan Period"
                      placeholder="Select period"
                      className="finance-form__input"
                      {...controllerProps}
                    />
                  )}
                />
              </div>
            </>
          )}
        </>
      )}

      {paymentMethod === 'financing' && preapprove === 'yes' && (
        <Controller
          control={control}
          name="approvalLetter"
          defaultValue={[]}
          render={({ value, ...controllerProps }) => (
            <FileInput
              label="Upload pre-approval letter"
              placeholder={getFilesLabel(value)}
              onFileUpload={onFileUpload}
              onFileRemove={onFileRemove}
              accept={FileInputACCEPT.ALL}
              files={value}
              {...controllerProps}
            />
          )}
        />
      )}

      {paymentMethod === 'cash' && (
        <div className="finance-form__radio-group">
          <div className="label">Do you have proof of funds?</div>
          <RadioButton ref={register} name="proof" value="yes" label="Yes" defaultChecked />
          <RadioButton ref={register} name="proof" value="no" label="No" />
        </div>
      )}
      {paymentMethod === 'cash' && proof === 'yes' && (
        <Controller
          control={control}
          name="approvalLetter"
          defaultValue={[]}
          render={({ value, ...controllerProps }) => (
            <FileInput
              label="Upload proof of funds"
              placeholder={getFilesLabel(value)}
              onFileUpload={onFileUpload}
              onFileRemove={onFileRemove}
              accept={FileInputACCEPT.ALL}
              files={value}
              {...controllerProps}
            />
          )}
        />
      )}
      <Button className="finance-form__submit-btn" primary>
        Continue
      </Button>
    </StyledForm>
  );
};

export default FinanceForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0 0 12px 0;
  }

  .finance-form__input-group {
    display: flex;
    justify-content: space-between;
  }

  .finance-form__input-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
    align-items: center;
    .finance-form__input {
      width: 100%;
    }
    .dropdown__button {
      height: 32px;
    }
  }

  .finance-form__radio-group {
    & > .label {
      margin-bottom: 8px;
    }
    .radiobutton {
      margin-right: 20px;
    }
  }

  .finance-form__slider-input {
    position: relative;
    .input {
      width: 183px;
      margin-bottom: 20px;
    }

    .slider {
      margin-bottom: 12px;
      .track {
        height: 4px;
        top: 4px;
      }
      .track-0 {
        background: ${props => props.theme.colors.primary};
      }
      .track-1 {
        background: #aab7c9;
      }
    }

    &__info {
      margin: 4px 0;
      color: #9c9c9e;

      strong {
        color: #000000;
        font-weight: 600;

        &.green {
          color: #11c494;
        }
      }
    }
  }

  .double-input label > .trigger {
    margin-right: -50px;
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .trigger {
    float: right;
    z-index: 10;
    circle {
      fill: ${props => props.theme.colors.lightGray};
    }
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }

  .finance-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .finance-form__input-group {
      flex-direction: column;

      .finance-form__slider-input {
        margin-bottom: 24px;
        .input {
          width: 100%;
        }
        .content {
          width: 320px;
        }
      }
    }
    .finance-form__input-container {
      grid-template-columns: 1fr;
      grid-gap: 12px 0;
    }
  }
`;

const StyledDoubleInput = styled.div`
  margin-bottom: 20px;
  display: flex;

  .input {
    margin: 0 !important;

    .input__component {
      border-width: 1px 0 1px 1px;
      border-radius: 3px 0 0 3px;
    }

    &.percent-input {
      width: 50px;
      margin: 0;
      align-self: flex-end;

      .input__component {
        border-width: 1px;
        border-radius: 0 3px 3px 0;
      }
    }
  }
`;
