import React from 'react';

import { formatCurrency } from 'utils/rifmFormatters';

const inspectionContigencyTexts = {
  asIs: 'As is',
  yes: 'Yes',
  no: 'No'
};

interface DeadlineSummaryProps {
  data: { [key: string]: any; };
  onEdit: (value: BuyProcessTabKey) => void;
}

const DeadlineSummary: React.FC<DeadlineSummaryProps> = ({ data, onEdit }) => {
  return (
    <div className="review-wrapper">
      <button className="review-block__edit-btn" onClick={() => onEdit('deadline')}>
        Edit
      </button>
      <div className="review-block">
        <div className="review-info-block">
          <h3 className="review-title">Deadline:</h3>
          <p className="review-text">
            <strong>Closing Date: </strong>
            {data?.deadlineDate || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Attorney: </strong>
            {data?.attorneyName || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Attorney Phone: </strong>
            {data?.attorneyPhone || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Attorney Email: </strong>
            {data?.attorneyEmail || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Inspection Contigency: </strong>
            {data?.inspectionChoice ? inspectionContigencyTexts[data.inspectionChoice] : 'N/A'}
          </p>
          <p className="review-text">
            <strong>Home Warranty: </strong>
            {data?.homeWarranty || 'N/A'}
          </p>
          {data?.homeWarranty && (
            <p className="review-text">
              <strong>Home Warranty Price: </strong>
              {data?.homeWarrantyPrice ? formatCurrency(data.homeWarrantyPrice) : 'N/A'}
            </p>
          )}
          <p className="review-text">
            <strong>Tax Proration: </strong>
            {data?.taxProration || 'N/A'}
          </p>
        </div>
        <div className="review-info-block">
          <div className="review-title empty" />
          <p className="review-text">
            <strong>Lender: </strong>
            {data?.lenderName || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Lender Phone: </strong>
            {data?.lenderPhone || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Lender Email: </strong>
            {data?.lenderEmail || 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeadlineSummary;
