import React, { useContext, useState } from 'react';

import { styled } from 'styles';
import { Button, Icon, Checkbox, Container } from 'components';
import BuyProcessContext from '../context';
import IntroSummary from './IntroSummary';
import InfoSummary from './InfoSummary';
import FinanceSummary from './FinanceSummary';
import DeadlineSummary from './DeadlineSummary';

interface ReviewProps {
  open?: boolean;
}

const Review: React.FC<ReviewProps> = () => {
  const {
    listing,
    infoTabData,
    financeTabData,
    deadlineTabData,
    activeTab,
    setActiveTab,
    onReviewSubmit
  } = useContext(BuyProcessContext);
  const [reviewed, setReviewed] = useState<boolean>(false);
  const open = activeTab === 'review';

  if (!open)
    return (
      <Container header number={5}>
        Review
      </Container>
    );

  return (
    <StyledReview>
      <Container header>
        <Icon name="real-estate1" width="24" height="24" />
        Please review your offer and make sure everything is alright before placing it
      </Container>

      <Container number={5} merged={open ? 'top' : undefined} header active>
        Review
      </Container>

      {open && (
        <Container merged="bottom">
          <div className="process-form">
            {listing && (
              <IntroSummary
                data={{ ...listing, offerAmount: financeTabData?.offerAmount }}
                onEdit={setActiveTab}
              />
            )}
            {infoTabData && <InfoSummary data={infoTabData} onEdit={setActiveTab} />}
            {financeTabData && <FinanceSummary data={financeTabData} onEdit={setActiveTab} />}
            {deadlineTabData && <DeadlineSummary data={deadlineTabData} onEdit={setActiveTab} />}
            <div className="submit-block">
              <Checkbox
                checked={reviewed}
                onChange={() => setReviewed(v => !v)}
                label={
                  <>
                    I have reviewed and agreed to{' '}
                    <a href="/privacy" target="_blank" rel="noreferer">
                      Terms and Conditions
                    </a>
                  </>
                }
              />
              <Button
                className="process-form__btn"
                primary
                disabled={!reviewed}
                onClick={onReviewSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Container>
      )}
    </StyledReview>
  );
};

const StyledReview = styled.div`
  .submit-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 45px 0 0 0;

    .process-form__btn {
      margin: 0;
    }
  }
  .review-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px;
    min-height: 24px;
  }
  .review-wrapper {
    border-bottom: 1px solid #c4c4c4;
    padding: 36px 0;
    /* margin: 0 0 32px; */
  }
  .review-header {
    display: flex;
    justify-content: space-between;
  }
  .review-block {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 32px;
    padding: 0 0 32px;
    flex: 1;
    &__border {
      border-style: solid;
      border-color: #c4c4c4;
      margin: 0 0 32px;
    }

    &:last-of-type {
      padding: 0;
    }

    &__edit-btn {
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.primary};
      margin-left: auto;
      outline: none;
      float: right;

      &:focus {
        opacity: 0.7;
      }
    }
  }
  .review-info-block {
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 12px;
    }
  }
  .review-text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px;
    font-weight: normal;
    strong {
      font-weight: 500;
    }
  }
  .choices-info {
    padding: 0 0 32px;
  }
  .choice-item {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #9c9c9e;
    margin: 0 0 8px;
    strong {
      font-weight: 500;
      color: black;
    }
  }
  .property-price {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin: 34px 0 0 0;
  }
  .property-block {
    flex: 1;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    display: flex;
    img {
      width: 36px;
      height: 36px;
      border-radius: 3px;
      margin-right: 12px;
      object-fit: cover !important;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .checkbox > label > a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .review-block {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .property-block {
      &:first-of-type {
        margin: 0 0 32px;
      }
    }
    .header-property-info {
      .review-title {
        margin: 0 0 16px;
      }
    }
    .submit-block {
      flex-direction: column;

      .checkbox {
        margin: 0 0 24px;
      }
    }
  }
`;

export default Review;
