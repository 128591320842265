import React from 'react';

import { Image } from 'components';
import noImage from 'assets/images/placeholder_no_image.jpg';
import { IMAGE_SIZES } from 'consts';
import { formatCurrency } from 'utils/rifmFormatters';

interface IntroSummaryProps {
  data: Partial<ListingDetails> & { offerAmount: string };
  onEdit: (value: BuyProcessTabKey) => void;
}

const IntroSummary: React.FC<IntroSummaryProps> = ({ data, onEdit }) => {
  const propertyPhoto = data.images?.[0];

  return (
    <div className="review-wrapper">
      <button className="review-block__edit-btn x" onClick={() => onEdit('intro')}>
        Edit
      </button>
      <div className="review-block">
        <div className="header-property-info">
          <h3 className="review-title">You’re making offer for:</h3>
          <span className="property-block">
            {propertyPhoto ? (
              <Image
                cloudFront={{ size: IMAGE_SIZES.small, key: propertyPhoto }}
                className="property-photo"
                alt="mini-propetry"
              />
            ) : (
              <img src={noImage} alt="mini-propetry" />
            )}

            <span>
              {data?.address1} {data?.address2} {data?.city}, {data?.state} {data?.postalCode}
            </span>
          </span>
        </div>

        {data?.price && (
          <div className="header-property-info">
            {data.offerAmount && (
              <>
                <h3 className="review-title">Offer Amount:</h3>
                <p className="property-price">{formatCurrency(data.offerAmount)}</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntroSummary;
