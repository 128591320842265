import { fetchWithToken } from 'api';
import { urlEncodeBody } from 'utils';

export async function generateValidationCode(phone: string, processId?: string): Promise<string> {
  const response = await fetchWithToken(`/validate/generate_code`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ phone, buy_process_id: processId })
  });
  const data = await response.json();
  return data.verification_code_id;
}

export async function submitValidationCode(values: {
  code: string;
  codeId: string;
}): Promise<void> {
  await fetchWithToken(`/validate/validate_code`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ verification_code: values.code, verification_code_id: values.codeId })
  });
}

export async function submitBuyProcessForm(values, listingId: string): Promise<string> {
  const formData = {
    data: JSON.stringify(values),
    listing_id: listingId
  };
  const response = await fetchWithToken(
    '/buy',
    { method: 'POST', body: urlEncodeBody(formData) },
    false
  );
  const data = await response.json();
  return data.buy_process_id;
}
