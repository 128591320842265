import React, { useContext } from 'react';

import { AuthContext } from 'auth';
import { Button, Icon } from 'components';
import Container from 'components/Container';
import AgentInfoForm from './AgentInfoForm';
import BuyerInfoForm from './BuyerInfoForm';
import BuyProcessContext from '../context';

interface InfoProps {
  open?: boolean;
}

const Info: React.FC<InfoProps> = () => {
  const { profile } = useContext(AuthContext);
  const { activeTab, introTabData, infoTabData, setInfoTabData, setActiveTab } = useContext(
    BuyProcessContext
  );
  const formDefaultValues = {
    name: profile?.name,
    email: profile?.email,
    phone: profile?.phone,
    ...infoTabData
  };
  const open = activeTab === 'info';

  const handleSubmit = value => {
    const nextTab = value.offerType === 'upload' ? 'review' : 'finance';
    setInfoTabData(value);
    setActiveTab(nextTab);
  };

  return (
    <>
      {open && (
        <Container info>
          <Icon name="real-estate1" /> Start your offer by filling out the details below.
        </Container>
      )}
      <Container number={2} header active={open} merged={open ? 'top' : undefined}>
        Your Information{' '}
        {infoTabData && !open && (
          <Button link onClick={() => setActiveTab('info')} className="edit-btn">
            Edit
          </Button>
        )}
      </Container>
      {open && (
        <Container merged="bottom">
          {introTabData?.buyerOrAgent === 'agent' ? (
            <AgentInfoForm
              defaultValues={formDefaultValues}
              onSubmit={values => handleSubmit({ ...values, type: 'agent' })}
            />
          ) : (
              <BuyerInfoForm
                defaultValues={formDefaultValues}
                onSubmit={values => handleSubmit({ ...values, type: 'buyer' })}
              />
            )}
        </Container>
      )}
    </>
  );
};

export default Info;
