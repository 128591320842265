import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import pluralize from 'pluralize';

import { Button, Input, FileInput, FileInputACCEPT, RadioButton, Textarea } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { uploadFileToS3 } from 'api/documents';
import { REGEXP } from 'consts';
import InfoFormContainer from './InfoFormContainer';

interface AgentInfoFormProps {
  defaultValues?: { [key: string]: any };
  onSubmit: (values: { [key: string]: any }) => void;
}

const AgentInfoForm: React.FC<AgentInfoFormProps> = ({ defaultValues, onSubmit }) => {
  const { register, errors, control, getValues, setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      ...defaultValues,
      agent_name: defaultValues?.name,
      agent_email: defaultValues?.email,
      agent_phone: defaultValues?.phone
    },
    mode: 'onSubmit'
  });
  const [showNext, setShowNext] = useState(false);
  const [showCobuyerField, setShowCobuyerField] = useState(false);
  const [showCompanyField, setShowCompanyField] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;

  const getFilesLabel = files => {
    const { length } = files;
    if (!length) return 'Select file...';
    return `${length} ${pluralize('file', length)}`;
  };

  const onFileUpload = async (fileToAdd, inputName) => {
    try {
      const url = await uploadFileToS3(fileToAdd);
      const files = getValues(inputName);
      setValue(inputName, [...files, { url, name: fileToAdd.name, size: fileToAdd.size }]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const onFileRemove = (fileToremove, inputName) => {
    const files = getValues(inputName).filter(file => file.id !== fileToremove.id);
    setValue(inputName, files);
  };

  const handleContinue = values => {
    if (hasErrors) return;
    if (!showNext) setShowNext(true);
    else onSubmit(values);
  };

  const offerType = watch('offerType');

  return (
    <InfoFormContainer>
      <form onSubmit={handleSubmit(handleContinue)}>
        <p className="info-form__title">Choose your offer style below</p>
        <div className="info-form__radio-group">
          <RadioButton
            ref={register}
            name="offerType"
            value="step"
            label="Step by Step"
            defaultChecked
            buttonLike
            className="info-form__radio-btn"
          />
          <RadioButton
            ref={register}
            name="offerType"
            value="upload"
            label="Upload an offer"
            buttonLike
            className="info-form__radio-btn"
          />
        </div>

        <p className="info-form__title">Agent Name</p>
        <div className="info-form__input-group">
          <Input
            className="info-form__input"
            ref={register({
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            name="agent_name"
            defaultValue=""
            error={errors.agent_name}
            label="Agent Name"
            placeholder="Enter Name"
          />
        </div>
        <div className="info-form__input-group">
          <Controller
            control={control}
            name="agent_phone"
            rules={{
              required: 'Required',
              validate: value => !value.includes('_') || 'Required'
            }}
            defaultValue=""
            render={controllerProps => (
              <Input
                className="info-form__input"
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.agent_phone}
                label="Phone Number*"
                placeholder="Enter Phone Number"
                {...controllerProps}
              />
            )}
          />
          <Input
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            name="agent_email"
            defaultValue=""
            error={errors.agent_email}
            className="info-form__input"
            label="Email Address*"
            placeholder="Enter Email"
          />
        </div>

        <div className="info-form__input-group">
          <Input
            ref={register}
            name="agent_brokerage_name"
            defaultValue=""
            error={errors.agent_brokerage_name}
            className="info-form__input"
            label="Agent Brokerage Name"
            placeholder="Enter Agent Brokerage Name"
          />
          <Input
            ref={register}
            name="agent_licence_number"
            defaultValue=""
            error={errors.agent_licence_number}
            className="info-form__input"
            label="Agent License Number"
            placeholder="Enter Agent License Number"
          />
        </div>

        {showNext && (
          <>
            <p className="info-form__title">Buyer Information</p>
            <div className="info-form__input-group">
              <Input
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: REGEXP.NAME,
                    message: 'Invalid name'
                  }
                })}
                name="buyer_1_name"
                defaultValue=""
                error={errors.buyer_1_name}
                className="info-form__input"
                label="Buyer Name*"
                placeholder="Enter Buyer Name"
              />
              <Controller
                control={control}
                name="buyer_1_phone"
                rules={{
                  required: 'Required',
                  validate: value => !value.includes('_') || 'Required'
                }}
                defaultValue=""
                render={controllerProps => (
                  <Input
                    className="info-form__input"
                    as={MaskedInput}
                    mask="+1 (999) 999-9999"
                    type="tel"
                    error={errors.buyer_1_phone}
                    label="Buyer Phone*"
                    placeholder="Enter Phone Number"
                    {...controllerProps}
                  />
                )}
              />
            </div>
            <div className="info-form__input-group">
              <Input
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: REGEXP.EMAIL,
                    message: 'Invalid email address'
                  }
                })}
                name="buyer_1_email"
                defaultValue=""
                error={errors.buyer_1_email}
                className="info-form__input"
                label="Buyer Email Address*"
                placeholder="Enter Email"
              />
            </div>

            <div className="info-form__radio-group">
              <p className="info-form__radio-label">Has your buyer visited the property?</p>
              <RadioButton
                ref={register}
                name="buyer_visited_property"
                value="Yes"
                label="Yes"
                defaultChecked
              />
              <RadioButton ref={register} name="buyer_visited_property" value="No" label="No" />
            </div>

            <div className="info-form__btn-group">
              <Button
                simple
                className="info-form__btn-add"
                onClick={e => {
                  e.preventDefault();
                  setShowCobuyerField(v => !v);
                }}>
                {showCobuyerField ? '-' : '+'} Add a co-buyer
              </Button>
            </div>

            {showCobuyerField && (
              <div className="info-form__input-group">
                <Input
                  className="info-form__input"
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: REGEXP.NAME,
                      message: 'Invalid name'
                    }
                  })}
                  name="buyer_2_name"
                  defaultValue=""
                  error={errors.buyer_2_name}
                  label="Co-buyer Full Name*"
                  placeholder="Enter Name"
                />
                <Input
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: REGEXP.EMAIL,
                      message: 'Invalid email address'
                    }
                  })}
                  name="buyer_2_email"
                  defaultValue=""
                  error={errors.buyer_2_email}
                  className="info-form__input"
                  label="Co-buyer Email Address*"
                  placeholder="Enter Email"
                />
              </div>
            )}

            <hr />

            <div className="info-form__btn-group">
              <Button
                className="info-form__btn-add"
                simple
                onClick={e => {
                  e.preventDefault();
                  setShowCompanyField(v => !v);
                }}>
                {showCompanyField ? '-' : '+'} Buying as a Company
              </Button>
            </div>
          </>
        )}

        {showCompanyField && (
          <div className="info-form__input-group">
            <Input
              className="info-form__input"
              ref={register({
                required: 'Required'
              })}
              name="company_name"
              defaultValue=""
              error={errors.company_name}
              label="Company Name*"
              placeholder="Enter Name"
            />
            <Input
              ref={register({
                required: 'Required',
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              name="company_email"
              defaultValue=""
              error={errors.company_email}
              className="info-form__input"
              label="Company Email Address*"
              placeholder="Enter Email"
            />
          </div>
        )}

        {offerType === 'upload' && showNext && (
          <>
            <p className="info-form__title">Upload Documents</p>
            <div className="info-form__input-group">
              <Controller
                control={control}
                name="official_offer"
                defaultValue={[]}
                render={controllerProps => (
                  <FileInput
                    label="Official Offer to Buy"
                    placeholder={getFilesLabel(controllerProps.value)}
                    onFileUpload={file => onFileUpload(file, 'official_offer')}
                    onFileRemove={file => onFileRemove(file, 'official_offer')}
                    files={controllerProps.value}
                    accept={FileInputACCEPT.ALL}
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="preapproval_pof"
                defaultValue={[]}
                render={controllerProps => (
                  <FileInput
                    label="Pre-approval letter / POF (proof of funds)"
                    placeholder={getFilesLabel(controllerProps.value)}
                    onFileUpload={file => onFileUpload(file, 'preapproval_pof')}
                    onFileRemove={file => onFileRemove(file, 'preapproval_pof')}
                    files={controllerProps.value}
                    accept={FileInputACCEPT.ALL}
                    {...controllerProps}
                  />
                )}
              />
            </div>
            <Textarea
              ref={register}
              name="additional_info"
              label="Additional Information"
              placeholder="Enter Additional Information"
              className="info-form__textarea"
            />
          </>
        )}

        <div className="info-form__submit-group">
          <Button className="info-form__btn-submit" primary>
            {showNext ? 'Continue' : 'Next'}
          </Button>
        </div>
      </form>
    </InfoFormContainer>
  );
};

export default AgentInfoForm;
